import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./layout.css"
import { Link } from "gatsby"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (      
      <div className="top-logo">
        <div>
          <StaticImage
            alt="Big Kid Studios logo of tall tree with extended branches"
            src="../images/logo-tree-100.png"          
          />
        </div>
        <h1 className="main-heading">        
          <Link to="/">    {title}</Link>
        </h1>
      </div>      
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a>
      </footer>
    </div>
  )
}

export default Layout
